<template>
  <div>
    <BaseNav></BaseNav>
    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
          <!-- Login card -->
          <form
            class="forgot-form"
            ref="login-form"
           
          >
            <div class="mb-0 ">
              <div class="">
                <div class="text-center mb-3">
                  <!-- <h5 class="mb-0">Forgot your password?</h5>
                  <p>Request a password reset link.</p> -->
                  <h5 class="mb-0 bold-title ">No panic!</h5>
                  <div class="sub-title-hint">Let’s recover your password in no time!</div>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="text"
                    v-model="forgotPassword.email"
                    name="email"
                    required
                    class="form-control"
                    placeholder="Email Address"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-user text-muted"></i>
                  </div>
                </div>

                <base-captcha></base-captcha>

                <div class="form-group d-flex align-items-center">
                  <div class="g-recaptcha" data-sitekey="your_site_key"></div>
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn--custom btn-block"
                    @click.prevent="submitReset"
                  >
                    {{
                      forgotPassword.busy ? "Loading.." : "Get Password Reset Link"
                    }}<i class="icon-circle-right2 ml-2"></i>
                  </button>
                </div>

                <div class="form-group d-flex align-items-center">
                  <router-link :to="{ name: 'Login' }" class=""
                    >Login to your account</router-link
                  >
                </div>

                <div class="form-group text-center text-muted content-divider">
                  <span class="px-2">Don't have an account?</span>
                </div>

                <div class="form-group">
                  <router-link
                    :to="{ name: 'Register' }"
                    class="btn btn--primary btn-block"
                    >Sign up</router-link
                  >
                </div>
              </div>
            </div>
          </form>
          <!-- /login card -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["auth/is_logged_in", "auth/user"]),
  },
  data() {
    return {
      forgotPassword: new this.$Form({
        email: "",
        token: "",
      }),
    };
  },
  methods: {
    ...mapActions("auth", ["doSendPasswordResetLink", "doGetAuthUser"]),
    submitReset() {
      // let grecaptcha = window.grecaptcha.getResponse()
      // let grecaptcha = "fasdflk";
      // if (!grecaptcha || grecaptcha == "") {
      //   this.$notify({
      //     title: "Error",
      //     text: "Please verify you are not a robot",
      //     style: "danger",
      //   });
      // } else {
        // this.forgotPassword.token = window.grecaptcha.getResponse();
        this.doSendPasswordResetLink(this.forgotPassword)
          .then((res) => {
            this.$notify({
              title: "Success",
              text: res.data ? res.data.Message : "Check your email for a password reset link",
              style: "success",
            });
            // if (this.$route.query.redirectAfterAuth) {
            //   document.location.href = this.$route.query.redirectAfterAuth;
            // } else {
            //   document.location.href = `/${process.env.VUE_APP_INSTALL_DIR}/login/`;
            // }
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });
          });
      }
    },
  // },
  mounted() {
    this.$parent.$emit("loader:hide");
  },
};
</script>


